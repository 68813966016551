/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = function onClientEntry() {
  // eslint-disable-next-line no-restricted-properties
  require.ensure(['@sentry/browser'], function execute(require) {
    const Sentry = require('@sentry/browser')
    Sentry.init({
      dsn: 'https://59c14e4eab354a75bd55a6ad57ec3ffe@sentry.io/1339159',
      environment: process.env.NODE_ENV,
      enabled: process.env.NODE_ENV === 'production',
    })
    window.Sentry = Sentry // eslint-disable-line no-undef
  })
}
